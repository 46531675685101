import { TruncaFrac } from './TruncaFrac';
import { SearchProps, TotalizadoresProps } from '../../protocols/SearchProps';
import { transformAsCurrency } from '~/utils/functions';

export function calculoTotalizadores(
  search: SearchProps[],
  totalizadores: TotalizadoresProps,
): TotalizadoresProps {
  search.forEach((item: SearchProps) => {
    const valConfReplace = transformAsCurrency(item.val_conf);

    totalizadores.val_lanc_manual += item.val_lanc_manual;
    totalizadores.val_sangria += item.val_sangria;
    totalizadores.val_mov_total += item.val_mov_total;

    totalizadores.val_conf += valConfReplace;
    totalizadores.val_fechamento += item.val_fechamento;

    totalizadores.val_doacao += item.val_doacao;
    totalizadores.val_troco_cred += item.val_troco_cred;
    totalizadores.val_cred_cli += item.val_cred_cli;
    totalizadores.val_super_troco += item.val_super_troco;
    totalizadores.val_vale_gas += item.val_vale_gas;
    totalizadores.val_f_receb_pgto += item.val_f_receb_pgto;
    totalizadores.val_recarga_cel += item.val_recarga_cel;
    totalizadores.val_corresp_banc += item.val_corresp_banc;
    totalizadores.val_receb_conta += item.val_receb_conta;
    totalizadores.val_cv_emissao += item.val_cv_emissao;
    totalizadores.val_reforco += item.val_reforco;
    totalizadores.val_abertura += item.val_abertura;
    totalizadores.val_provento += item.val_provento;

    totalizadores.val_conf_lib =
      TruncaFrac(item.val_conf_liberador, 2) +
      TruncaFrac(totalizadores.val_conf_lib, 2);
    totalizadores.val_dif =
      TruncaFrac(item.val_dif, 2) + TruncaFrac(totalizadores.val_dif, 2);
  });
  return totalizadores;
}
