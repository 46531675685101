import { fltRound } from '~/utils/functions';
import { TotalizadoresProps } from '../../protocols/SearchProps';

export function vendaLiqCalc(totalizadores: TotalizadoresProps): number {
  return fltRound(
    totalizadores.val_mov_total -
      (totalizadores.val_abertura +
        totalizadores.val_reforco +
        totalizadores.val_corresp_banc +
        totalizadores.val_receb_conta +
        totalizadores.val_recarga_cel +
        totalizadores.val_vale_gas +
        totalizadores.val_super_troco +
        totalizadores.val_f_receb_pgto +
        totalizadores.val_troco_cred +
        totalizadores.val_cred_cli +
        totalizadores.val_doacao +
        totalizadores.val_provento),
    2,
  );
}
