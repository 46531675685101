import { TruncaFrac } from './TruncaFrac';
import { SearchProps } from '../../protocols/SearchProps';

interface CalcTotalizadoresProps {
  val_dif: number;
  val_conf_liberador: number;
}

export function calculoValDifTotalizadoresEConfLiberador(
  search: SearchProps[],
): CalcTotalizadoresProps {
  const calcTotalizadores = { val_dif: 0, val_conf_liberador: 0 };

  search.forEach((element: SearchProps) => {
    calcTotalizadores.val_dif =
      TruncaFrac(element.val_dif, 2) + TruncaFrac(calcTotalizadores.val_dif, 2);

    calcTotalizadores.val_conf_liberador =
      TruncaFrac(element.val_conf_liberador, 2) +
      TruncaFrac(calcTotalizadores.val_conf_liberador, 2);
  });

  return calcTotalizadores;
}
