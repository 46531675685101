import { TruncaFrac } from './TruncaFrac';
import { SearchProps } from '../../protocols/SearchProps';

export function calculoLiberarRow(search: SearchProps[]): SearchProps[] {
  const liberar = search;
  search.forEach((item: SearchProps, index: number) => {
    if (
      item.tipo_status === 1 ||
      item.tipo_status === 3 ||
      item.tipo_status === 4
    ) {
      liberar[index].liberar = 0;
      liberar[index].checked = false;
    } else if (
      TruncaFrac(item.val_fech_liq, 2) ===
      TruncaFrac(item.val_conf_liberador, 2)
    ) {
      liberar[index].liberar = 2;
    } else {
      liberar[index].liberar = 1;
      liberar[index].checked = true;
    }
  });

  return liberar;
}
