import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .containerStatus {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-content: center;

    @media screen and (max-width: 991px) {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }

  .green {
    background-color: #b3dab6;
    border-left: 1px solid #31af39;
    border-right: 1px solid #31af39;
  }

  .table {
    margin-bottom: 0px;
    margin-top: 0px;

    .containerTd {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20px;
    }
  }
  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #6b778c;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  @media screen and(max-width: 991px) {
    .totalizadoresRow {
      td {
        backgroundcolor: purple;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .containerTd {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px !important;
      min-width: 100px !important;
    }
    .containerTableTotalizadores {
      overflow: auto;

      .table tbody tr td {
        max-width: 150px;
        min-width: 80px;
      }
    }
  }
`;

export const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 0px;
  color: #313638;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 40px;
`;
export const Text = styled.h3`
  font-size: 21px;
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;

export const ContainerButtCapa = styled.div`
  display: flex;
  flex-direction: columns;
  margin-top: 10px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;

    .colButton {
      min-width: 170px;
      max-width: 170px;
      height: 42px;
    }
    .colButton2 {
      min-width: 170px;
      max-width: 170px;
      height: 42px;
    }
  }

  @media screen and (max-width: 767px) {
    .row {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0px;

    .colButton {
      min-width: 31%;
      max-width: 31%;
      margin-bottom: 5px;
      padding-right:0px !important;
      padding-left:0px !important;
      backgroundColor: green !important;
    }
    .colButton2 {
      min-width: 31%;
      max-width: 31%;
      padding-right:0px !important;
      padding-left:0px !important;
      backgroundColor: blue;
    }
    .colButton .confirm {
      min-width: 100% !important;
    }
    .colButton .clear {
      min-width: 100% !important;
    }
    .colButton2 .confirm {
      min-width: 100% !important;
    }
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 150px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.search {
    color: #ffffff;
    border: none;
    height: 100%;
    max-height: 42px;
    min-width: 150px;
    background-color: #8850bf;
    &:disabled {
      background-color: #b192d1;
      cursor: not-allowed;
      &:hover {
        background-color: #b192d1;
        opacity: 1;
      }
    }

    @media screen and (max-width: 767px) {
      max-width: 150px !important;
      min-width: 100% !important;
      height: 100%;
    }
  }
  &.confirm {
    background-color: #007bff;
    &:disabled {
      background-color: #6db3ff;
      cursor: not-allowed;
      &:hover {
        background-color: #6db3ff;
        opacity: 1;
      }
    }
  }
  &.imprimir {
    @media screen and (max-width: 767px) {
      max-width: 32% !important;
      min-width: 32% !important;
    }
    background-color: #808080;
    &:disabled {
      background-color: #aaaaaa;
      cursor: not-allowed;
      &:hover {
        background-color: #aaaaaa;
        opacity: 1;
      }
    }
  }

  &.excluir {
    @media screen and (max-width: 767px) {
      max-width: 33% !important;
      min-width: 33% !important;
    }
    background-color: #dc3545;
    &:disabled {
      background-color: #e3bec1;
      cursor: not-allowed;
      &:hover {
        background-color: #e3bec1;
        opacity: 1;
      }
    }
  }
  &.validar {
    @media screen and (max-width: 767px) {
      max-width: 33% !important;
      min-width: 33% !important;
    }
    background-color: #28a745;
    &:disabled {
      background-color: #b7e5c1;
      cursor: not-allowed;
      &:hover {
        background-color: #b7e5c1;
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    margin-left: 8px;
  }
`;
