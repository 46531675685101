import api from '~/services/api';
import { SelectProps } from '../../protocols/SelectProps';

export async function getOperador(
  dta_mov: string,
  cod_loja: number,
  num_pdv: number,
): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/liberacao-tesouraria/operador/`, {
      params: {
        dta_mov,
        cod_loja,
        num_pdv,
      },
    });
    if (data.data) {
      const operador = data.data
        .filter((v: any) => v.flg_inativo !== true)
        .map((value: any) => {
          return {
            label: `${value.cod_operador} - ${value.des_operador}`,
            value: value.cod_operador,
          };
        });

      return operador;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
