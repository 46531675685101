import React, { useState } from 'react';

import { Button, Text } from '../../styles';
import { Row, Col, Accordion } from 'react-bootstrap';

import { useLiberacaoTesouraria } from '../../LiberacaoTesourariaContext';
import { CustomSeparator } from '../CustomSepartor';
import { moneyFormat } from '~/utils/functions';

const Totalizadores: React.FC = () => {
  const { totalizadores, status, valDifTotal, confLiberadorTotal } =
    useLiberacaoTesouraria();
  const [showParams, setShowParams] = useState<string>('0');

  return (
    <>
      <Row>
        <Col className="containerStatus" md={4} sm={12}>
          <Text style={{ fontSize: '15px', color: 'red' }}>{status}</Text>
        </Col>
        <Col md={8} sm={12}>
          <div className="col-12  mt-2 containerTableTotalizadores">
            <table className="table">
              <thead>
                <tr>
                  <th>Manual</th>
                  <th>Retirada</th>
                  <th>Valor</th>
                  <th>Conf.</th>
                  <th>Conf. Lib.</th>
                  <th>Dif</th>
                  <th>Val. Fech</th>
                </tr>
              </thead>
              <tbody>
                {totalizadores && (
                  <tr className="totalizadoresRow">
                    <td>
                      {moneyFormat(String(totalizadores.val_lanc_manual))}
                    </td>
                    <td>{moneyFormat(String(totalizadores.val_sangria))}</td>
                    <td>{moneyFormat(String(totalizadores.val_mov_total))}</td>
                    <td>{moneyFormat(String(totalizadores.val_conf))}</td>
                    <td
                      className="containerTd"
                      style={{
                        maxWidth: '100px',
                      }}
                      title={
                        moneyFormat(String(confLiberadorTotal)).length < 12
                          ? ''
                          : moneyFormat(String(confLiberadorTotal))
                      }
                    >
                      {moneyFormat(String(confLiberadorTotal))}
                    </td>
                    <td
                      className="containerTd"
                      style={{
                        maxWidth: '100px',
                        color: `${
                          valDifTotal < 0
                            ? 'red'
                            : valDifTotal > 0
                            ? 'blue'
                            : 'black'
                        }`,
                      }}
                      title={
                        moneyFormat(String(valDifTotal)).length < 12
                          ? ''
                          : moneyFormat(String(valDifTotal))
                      }
                    >
                      {moneyFormat(String(valDifTotal))}
                    </td>
                    <td>{moneyFormat(String(totalizadores.val_fechamento))}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Accordion defaultActiveKey="0">
        <div className="row accordion-row">
          <div className="col-12" style={{ paddingRight: '0px' }}>
            <CustomSeparator
              label="Totalizadores"
              eventKey={showParams}
              onChange={(newEventKey: string) => {
                setShowParams(newEventKey);
              }}
            />
          </div>
        </div>
        <div id="accordionXml" className="accordion">
          <Accordion.Collapse
            as={Button}
            eventKey={showParams}
            className="row xml-data"
          >
            <div>
              <Row>
                <Col md={12} sm={12}>
                  <div className="col-12  mt-2 containerTableTotalizadores">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Doação</th>
                          <th>Troco Créd.</th>
                          <th>Créd. Cli.</th>
                          <th>Sup. Troco</th>
                          <th>Vale Gás</th>
                          <th>F. Receb/Pgto</th>
                          <th>Rec. Cel.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moneyFormat(String(totalizadores.val_doacao))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_troco_cred))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_cred_cli))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_super_troco))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_vale_gas))}
                          </td>
                          <td>
                            {moneyFormat(
                              String(totalizadores.val_f_receb_pgto),
                            )}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_recarga_cel))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <div className="col-12  mt-1 containerTableTotalizadores">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Corr. Banc.</th>
                          <th>Receb. Conta</th>
                          <th>C. Vale Emiss.</th>
                          <th>Reforço</th>
                          <th>Abertura</th>
                          <th>Proventos</th>
                          <th>Venda Liq.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moneyFormat(
                              String(totalizadores.val_corresp_banc),
                            )}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_receb_conta))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_cv_emissao))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_reforco))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_abertura))}
                          </td>
                          <td>
                            {moneyFormat(String(totalizadores.val_provento))}
                          </td>
                          <td
                            className="containerTd"
                            title={
                              moneyFormat(String(totalizadores.venda_liq))
                                .length < 12
                                ? ''
                                : moneyFormat(String(totalizadores.venda_liq))
                            }
                          >
                            {moneyFormat(String(totalizadores.venda_liq))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};

export default Totalizadores;
