import api from '~/services/api';
import { SelectProps } from '../../protocols/SelectProps';

export async function getTurno(
  dta_mov: string,
  num_pdv: number,
  cod_operador: number,
  cod_loja: number,
): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(`/liberacao-tesouraria/turno/`, {
      params: {
        dta_mov,
        cod_loja,
        num_pdv,
        cod_operador,
      },
    });
    if (data.data) {
      const turno = data.data.map((value: any) => {
        return { label: String(value.num_turno), value: value.num_turno };
      });

      return turno;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
