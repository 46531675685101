import { SearchProps } from '../../protocols/SearchProps';

export function calculoStatusConferencia(search: SearchProps[]): string {
  let parcial = false;
  let turno = '';
  let numTurno: any;
  search.forEach((element: SearchProps) => {
    if (
      element.tipo_status !== 3 &&
      element.val_conf_liberador !== 0 &&
      element.liberar === 2
    ) {
      parcial = true;

      if (turno === '') {
        turno = `(TURNO  ${element.num_turno}`;
        numTurno = element.num_turno;
      } else if (numTurno !== element.num_turno) {
        turno = `${turno} E ${element.num_turno}`;
        numTurno = element.num_turno;
      }
    }
  });

  if (parcial) {
    turno = `Status: LIBERADO PARCIALMENTE ${turno})`;
  }
  return turno;
}
