import api from '~/services/api';
import { nanoid } from 'nanoid';
import { fltRound, formatCurrencyAsText } from '~/utils/functions';
import { SearchProps } from '../../protocols/SearchProps';

export async function getSearch(
  dta_mov: string,
  num_pdv: number,
  cod_operador: number,
  cod_loja: number,
  num_turno: number,
): Promise<SearchProps[]> {
  try {
    const { data } = await api.get(`/liberacao-tesouraria/search/`, {
      params: {
        dta_mov,
        num_pdv,
        cod_operador,
        cod_loja,
        num_turno,
      },
    });

    if (data.data) {
      const search = data.data.map((value: any) => {
        value.id = nanoid();
        value.val_dif = formatCurrencyAsText(
          Math.abs(fltRound(value.val_mov - value.val_conf, 2)),
        );

        return value;
      });
      return search;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
