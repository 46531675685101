import React from 'react';
import { LiberacaoTesourariaProvider } from './LiberacaoTesourariaContext';
import { LiberacaoTesourariaContent } from './LiberacaoTesourariaContent';

export const LiberacaoTesouraria: React.FC = () => {
  return (
    <LiberacaoTesourariaProvider>
      <LiberacaoTesourariaContent />
    </LiberacaoTesourariaProvider>
  );
};
