import { SearchProps } from '../../protocols/SearchProps';

export function calculoDesFinalizadora(search: SearchProps[]): SearchProps[] {
  const searchUpdate = search;

  search.forEach((item: SearchProps, index: number) => {
    if (item.tipo_status !== 4 || Number(item.val_conf) === 0) {
      searchUpdate[index].des_finalizadora_calc =
        searchUpdate[index].des_finalizadora;
    }
    if (item.tipo_status === 4 && Number(item.val_conf) > 0) {
      searchUpdate[
        index
      ].des_finalizadora_calc = `${searchUpdate[index].des_finalizadora} (QUEBRA DE CAIXA)`;
    }
    if (item.tipo_status === 4 && Number(item.val_conf) < 0) {
      searchUpdate[
        index
      ].des_finalizadora_calc = `${searchUpdate[index].des_finalizadora_calc} (SOBRA DE CAIXA)`;
    }
  });

  return searchUpdate;
}
