import { TruncaFrac } from './TruncaFrac';
import { SearchProps } from '../../protocols/SearchProps';

export function calculoValDifRow(search: SearchProps[]): SearchProps[] {
  search.forEach((item: SearchProps, index: number) => {
    if (
      TruncaFrac(item.val_conf_liberador, 2) -
        TruncaFrac(item.val_mov_total, 2) ===
      0
    ) {
      search[index].val_dif = 0.0;
    } else {
      search[index].val_dif = TruncaFrac(
        item.val_conf_liberador - item.val_mov_total,
        2,
      );
    }
  });

  return search;
}
