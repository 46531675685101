import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';

import { CgSmile, CgSmileNeutral, CgSmileSad } from 'react-icons/cg';
import { moneyFormat } from '~/utils/functions';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { ContainerPagination } from '../../styles';
import { useLiberacaoTesouraria } from '../../LiberacaoTesourariaContext';
import { nanoid } from 'nanoid';
import { MdCheck } from 'react-icons/md';

export const DataGridComponent: React.FC = () => {
  const {
    register,
    errors,
    searchRows,
    calculoValDif,
    handleConfLiberador,
    getValues,
    setSearchRows,
  } = useLiberacaoTesouraria();

  const Paginated: React.FC = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <p className="my-2">
            {`${searchRows.length} registro(s) encontrado(s)`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: '10px',
        }}
      >
        <Col md={12} sm={12}>
          <Paper style={{ width: '100%' }}>
            <TableContainer style={{ width: '100%', height: '480px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Liberar?</TableCell>
                    <TableCell>Turno</TableCell>
                    <TableCell>Restrição</TableCell>
                    <TableCell>Finalizadora</TableCell>
                    <TableCell align="right">Qtd. Registro</TableCell>
                    <TableCell align="right">Valor</TableCell>
                    <TableCell align="right">Conferência</TableCell>
                    <TableCell align="right">Conf. Liberador</TableCell>
                    <TableCell align="right">Diferença</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchRows.map((row: any, index: number) => {
                    const isChecked = row.checked;
                    return (
                      <TableRow
                        style={{
                          backgroundColor: `${
                            row.tipo_status === 4 ? '#ffff77' : ''
                          }`,
                          borderColor: `${
                            row.tipo_status === 4 ? 'yellow' : ''
                          }`,
                        }}
                        key={row.cod_tesouraria}
                      >
                        <TableCell
                          style={{
                            textAlign: 'center',
                            width: '5%',
                          }}
                        >
                          {row.liberar === 2 && (
                            <MdCheck
                              size={20}
                              style={{
                                color: 'green',
                                textAlign: 'center',
                              }}
                            />
                          )}
                          {row.liberar !== 2 && (
                            <Form.Check
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              type="checkbox"
                              id={nanoid()}
                              checked={isChecked}
                              onClick={() => {
                                const searchRowsChecked = searchRows.map(
                                  (item: any) => {
                                    if (
                                      item.cod_tesouraria === row.cod_tesouraria
                                    ) {
                                      item.checked = !item.checked;
                                    }
                                    return item;
                                  },
                                );
                                setSearchRows(searchRowsChecked);
                              }}
                              size={5}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            width: '5%',
                            textAlign: 'center',
                          }}
                        >
                          {row.num_turno}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: 'center',
                            width: '5%',
                          }}
                        >
                          {Number(row.tipo_status) === 1 && (
                            <CgSmile
                              size={25}
                              style={{
                                color: '#72ab90',
                              }}
                            />
                          )}
                          {Number(row.tipo_status) === 2 && (
                            <CgSmileSad
                              size={25}
                              style={{ color: '#ed281a' }}
                            />
                          )}
                          {Number(row.tipo_status) === 3 && (
                            <CgSmileNeutral
                              size={25}
                              style={{ color: '#f2bd00' }}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            width: '25%',
                          }}
                        >
                          {row.des_finalizadora_calc}
                        </TableCell>

                        <TableCell
                          style={{
                            width: '15%',
                            textAlign: 'right',
                          }}
                        >
                          {row.qtd_registro}
                        </TableCell>
                        <TableCell
                          className={`${row.tipo_status === 4 ? '' : 'green'}`}
                          style={{
                            width: '5%',
                            textAlign: 'right',
                          }}
                        >
                          {moneyFormat(row.val_mov_total)}
                        </TableCell>
                        <TableCell
                          style={{
                            width: '5%',
                            textAlign: 'right',
                          }}
                        >
                          {moneyFormat(row.val_conf)}
                        </TableCell>
                        {row.liberar !== 2 && (
                          <TableCell
                            style={{
                              width: '10%',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <input
                              style={{ width: '120px', textAlign: 'right' }}
                              placeholder="0,00"
                              {...register(
                                `cod_tesouraria-${row.cod_tesouraria}`,
                              )}
                              className={
                                errors.teste
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              type="string"
                              onChange={(event: any) => {
                                handleConfLiberador(
                                  row.cod_tesouraria,
                                  event.target.value,
                                );

                                const searchRowsChecked = searchRows;

                                searchRowsChecked.map((item: any) => {
                                  if (
                                    item.cod_tesouraria === row.cod_tesouraria
                                  ) {
                                    item.checked = true;
                                  }
                                  return item;
                                });
                                setSearchRows(searchRowsChecked);

                                calculoValDif(index, event.target.value);
                              }}
                            />
                          </TableCell>
                        )}
                        {row.liberar === 2 && (
                          <TableCell
                            align="right"
                            style={{
                              width: '10%',
                            }}
                          >
                            <div style={{ width: '100%' }}>
                              {getValues(
                                `cod_tesouraria-${row.cod_tesouraria}`,
                              )}
                            </div>
                          </TableCell>
                        )}

                        <TableCell
                          style={{
                            width: '15%',
                            textAlign: 'right',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '20px',
                          }}
                          title={
                            moneyFormat(row.val_dif).length < 12
                              ? ''
                              : moneyFormat(row.val_dif)
                          }
                        >
                          {moneyFormat(row.val_dif)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {searchRows.length === 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={12}
                      style={{
                        width: '100%',
                        height: '480px',
                      }}
                    >
                      Nenhum registro encontrado...
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
            <ContainerPagination>
              <Paginated />
            </ContainerPagination>
          </Paper>
        </Col>
      </Row>
    </>
  );
};
