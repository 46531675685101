import React from 'react';

import { ContainerButtCapa } from '../../styles';
import { Row, Col } from 'react-bootstrap';

import { MdOutlineCancel } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { FaEraser } from 'react-icons/fa';
import { useLiberacaoTesouraria } from '../../LiberacaoTesourariaContext';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

const ContainerButtonCapa: React.FC = () => {
  const {
    loaderSearch,
    disableButtonCancel,
    disableButtonClear,
    disableButtonSearch,
    handleSearch,
    resetInput,
    handleCancel,
  } = useLiberacaoTesouraria();
  return (
    <ContainerButtCapa>
      <Row style={{ width: '100%' }}>
        <Col md={12} sm={12} className="colButton">
          <CustomButtonNovo
            disabled={disableButtonSearch}
            onClick={() => {
              handleSearch();
            }}
            label="Pesquisar"
            icon={IoIosSearch}
            showLoading={loaderSearch}
          />
        </Col>
        <Col md={12} sm={12} className="colButton">
          <CustomButtonNovo
            disabled={disableButtonCancel}
            onClick={() => handleCancel()}
            label="Cancelar"
            icon={MdOutlineCancel}
            variant="cancel"
          />
        </Col>
        <Col md={12} sm={12} className="colButton2">
          <CustomButtonNovo
            onClick={() => resetInput()}
            disabled={disableButtonClear}
            label="Limpar"
            icon={FaEraser}
            variant="clear"
          />
        </Col>
      </Row>
    </ContainerButtCapa>
  );
};

export default ContainerButtonCapa;
