import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';

import { LojaContext } from '~/context/loja';
import { getPdvs } from './services/getPdvs';
import { toast } from 'react-toastify';
import { getOperador } from './services/getOperador';
import { getTurno } from './services/getTurno';
import { SelectProps } from './protocols/SelectProps';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';
import { getSearch } from './services/getSearch';
import { SearchProps, TotalizadoresProps } from './protocols/SearchProps';
import { moedaFormatada } from './formatMask';
import { vendaLiqCalc } from './function/auxiliares/vendaLiqCalc';
import { calculoValDifTotalizadoresEConfLiberador } from './function/auxiliares/calculoValDifTotalizadoresEConfLiberador';
import { calculoStatusConferencia } from './function/auxiliares/calculoStatusConferencia';
import { calculoLiberarRow } from './function/auxiliares/calculoLiberarRow';
import { calculoValDifRow } from './function/auxiliares/calculoValDifRow';
import { calculoDesFinalizadora } from './function/auxiliares/calculoDesFinalizadora';
import { calculoTotalizadores } from './function/auxiliares/calculoTotalizadores';
import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';

interface LiberacaoTesourariaContextData {
  handleLoja: (val: number) => void;
  handleChangeLojaAndDate: () => void;
  handledisableCaixa: (val: boolean) => void;
  handleChangeCaixa: () => void;
  handleCancel: () => void;
  handleGetTurnos: () => void;
  handleSearch: () => void;
  resetInput: () => void;
  handleDelete: () => void;
  handleImprimir: () => void;
  handleValidar: () => void;
  calculoValDif: (index: number, value: string) => void;
  handleConfLiberador: (cod_tesouraria: number, value: string) => void;
  setSearchRows: (value: SearchProps[]) => void;

  valDifTotal: number;
  searchRows: SearchProps[];
  optionsTurno: SelectProps[];
  optionsOperador: SelectProps[];
  optionsPdv: SelectProps[];
  lojaConferencia: number;
  disableInputs: boolean;
  disableCaixa: boolean;
  disableOperador: boolean;
  disableTurno: boolean;
  loaderSearch: boolean;
  disableButtonCancel: boolean;
  disableButtonClear: boolean;
  disableButtonSearch: boolean;
  disabledButtonImprimir: boolean;
  disableButtonValidar: boolean;
  totalizadores: TotalizadoresProps;
  status: string;
  confLiberadorTotal: number;

  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  getValues: UseFormGetValues<FieldValues>;
}

interface ProviderLiberacaoTesourariaProps {
  children: ReactNode;
}

export const LiberacaoTesourariaContext = createContext(
  {} as LiberacaoTesourariaContextData,
);

const MySwal = withReactContent(Swal);
export function LiberacaoTesourariaProvider({
  children,
}: ProviderLiberacaoTesourariaProps) {
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalizadoresDefault = {
    val_lanc_manual: 0,
    val_sangria: 0,
    val_mov_total: 0,
    val_conf: 0,
    val_fechamento: 0,
    val_doacao: 0,
    val_troco_cred: 0,
    val_cred_cli: 0,
    val_super_troco: 0,
    val_vale_gas: 0,
    val_f_receb_pgto: 0,
    val_recarga_cel: 0,
    val_corresp_banc: 0,
    val_receb_conta: 0,
    val_cv_emissao: 0,
    val_reforco: 0,
    val_abertura: 0,
    val_provento: 0,
    val_conf_lib: 0,
    val_dif: 0,
    venda_liq: 0,
  };

  const { loja } = useContext(LojaContext);
  const [lojaConferencia, setLojaConferencia] = useState<number>(loja.cod_loja);
  const [disableInputs, setDisableInputs] = useState<boolean>(false);
  const [disableCaixa, setDisableCaixa] = useState<boolean>(true);
  const [optionsPdv, setOptionsPdv] = useState<SelectProps[]>([]);
  const [disableOperador, setDisableOperador] = useState<boolean>(true);
  const [optionsOperador, setOptionsOperador] = useState<SelectProps[]>([]);
  const [disableTurno, setDisableTurno] = useState<boolean>(true);
  const [optionsTurno, setOptionsTurno] = useState<SelectProps[]>([]);
  const [loaderSearch, setLoaderSearch] = useState<boolean>(false);
  const [disableButtonCancel, setDisableButtonCancel] = useState<boolean>(true);
  const [disableButtonClear, setDisableButtonClear] = useState<boolean>(false);
  const [disableButtonSearch, setDisableButtonSearch] =
    useState<boolean>(false);
  const [disableButtonValidar, setDisableButtonValidar] =
    useState<boolean>(true);
  const [disabledButtonImprimir, setDisabledButtonImprimir] =
    useState<boolean>(true);
  const [searchRows, setSearchRows] = useState<SearchProps[]>([]);
  const [status, setStatus] = useState<string>('');
  const [valDifTotal, setValDifTotal] = useState<number>(0);
  const [confLiberadorTotal, setConfLiberadorTotal] = useState<number>(0);
  const [totalizadores, setTotalizadores] =
    useState<TotalizadoresProps>(totalizadoresDefault);

  useEffect(() => {
    setValue('searchValue', []);
    setValue('totalizadores', totalizadoresDefault);
  }, []);

  const handledisableButtonClear = useCallback((value: boolean) => {
    setDisableButtonClear(value);
  }, []);
  const handledisableButtonCancel = useCallback((value: boolean) => {
    setDisableButtonCancel(value);
  }, []);
  const handlesearchRows = useCallback(
    (value: SearchProps[]) => {
      setValue('searchValue', value);
      setSearchRows(value);
    },
    [setValue],
  );

  const handleDelete = useCallback(() => {
    console.log('handleloja');
  }, []);
  const handleImprimir = useCallback(() => {
    console.log('handleloja');
  }, []);
  const handleValidar = useCallback(() => {
    console.log('handleloja');
  }, []);

  const handledisableTurno = useCallback((value) => {
    setDisableTurno(value);
  }, []);

  const handleConfLiberador = useCallback(
    (cod_tesouraria: number, value) => {
      setConfLiberadorTotal(value);
      setValue(`cod_tesouraria-${cod_tesouraria}`, moedaFormatada(value));
    },
    [setValue],
  );
  const handleTotalizadores = useCallback(
    async (value) => {
      setValue('totalizadores', value);
      setTotalizadores(value);
    },
    [setValue],
  );
  const handleOptionsTurno = useCallback((value: SelectProps[]) => {
    setOptionsTurno(value);
  }, []);

  const handleLoja = useCallback((val) => {
    setLojaConferencia(val);
  }, []);
  const handleOptionsOperador = useCallback((val) => {
    setOptionsOperador(val);
  }, []);
  const handleOptionsPdv = useCallback((val) => {
    setOptionsPdv(val);
  }, []);

  const handledisableCaixa = useCallback((val) => {
    setDisableCaixa(val);
  }, []);

  const handledisableOperador = useCallback((val) => {
    setDisableOperador(val);
  }, []);

  const handleDisableWhenSearch = useCallback((value: boolean) => {
    setDisableInputs(value);
    setDisableCaixa(value);
    setDisableOperador(value);
    setDisableTurno(value);
    setDisableButtonSearch(value);
  }, []);

  const statusConferencia = useCallback((search: SearchProps[]) => {
    const stats = calculoStatusConferencia(search);
    setStatus(stats);
  }, []);

  const calcValDif = useCallback(() => {
    const search = getValues('searchValue');

    const searchWithValDif = calculoValDifRow(search);
    const calc = calculoValDifTotalizadoresEConfLiberador(searchWithValDif);

    setConfLiberadorTotal(calc.val_conf_liberador);
    setValDifTotal(calc.val_dif);
  }, [getValues]);

  const calculoLiberar = useCallback((search: SearchProps[]) => {
    const calcLiberar = calculoLiberarRow(search);
    setSearchRows(calcLiberar);
  }, []);

  const calcValDifTotalizadoresELiberador = useCallback((search) => {
    const calc = calculoValDifTotalizadoresEConfLiberador(search);

    setConfLiberadorTotal(calc.val_conf_liberador);
    setValDifTotal(calc.val_dif);
  }, []);

  const calculoValDif = useCallback(
    (index: number, event: string) => {
      const searchRowsValue = searchRows;
      searchRowsValue[index].val_conf_liberador = transformAsCurrency(event);

      const searchWithValDif = calculoValDifRow(searchRowsValue);

      setSearchRows(searchWithValDif);
      calcValDifTotalizadoresELiberador(searchRowsValue);
    },
    [searchRows, calcValDifTotalizadoresELiberador],
  );

  const calculoDesFinalizadorCalc = useCallback((search: SearchProps[]) => {
    const calcDes = calculoDesFinalizadora(search);

    setSearchRows(calcDes);
  }, []);

  const calcTotalizadores = useCallback(
    async (search) => {
      const totalizadoresPrev = getValues('totalizadores');

      const total: any = await calculoTotalizadores(search, totalizadoresPrev);

      total.venda_liq = vendaLiqCalc(total);

      handleTotalizadores(total);
    },
    [getValues, handleTotalizadores],
  );

  const handleSearch = useCallback(async () => {
    const { dta_mov, lojas, caixa, operador, turno } = getValues();

    if (dta_mov && lojas && caixa && operador) {
      setLoaderSearch(true);

      const search = await getSearch(
        dta_mov,
        caixa.value,
        operador.value,
        lojas,
        turno.value,
      );
      let buttonImprimirDisabled = true;

      for (let index = 0; index < search.length; index++) {
        if (
          search[index].tipo_status === 1 ||
          search[index].tipo_status === 2
        ) {
          buttonImprimirDisabled = false;
        }
      }

      setDisabledButtonImprimir(buttonImprimirDisabled);
      if (search.length > 0) {
        const searchWithValDif = calculoValDifRow(search);
        handlesearchRows(searchWithValDif);
        setDisableButtonCancel(false);
        setDisableButtonClear(true);
        setDisableButtonValidar(false);
        handleDisableWhenSearch(true);
        calculoLiberar(search);
        calcValDif();
        calculoDesFinalizadorCalc(search);
        statusConferencia(search);
        search.forEach((element: any) => {
          setValue(
            `cod_tesouraria-${element.cod_tesouraria}`,
            moneyFormat(element.val_conf_liberador),
          );
        });
      } else {
        handlesearchRows([]);

        MySwal.fire({
          title: ``,
          html: `<div style='text-align: left'>Nenhum registro encontrado, conforme critérios informados!</div></br>
          <div style='text-align: left'>***A T E N Ç Ã O***</div> 
          <div style='text-align: left'>Verifique as possíveis,
          ocorrências:</div>
          <div style='text-align: left'>- Finalizadora não vinculado ao operador informado.</div>
          <div style='text-align: left'>- Cliente não vinculado ao operador informado.</div>
          <div style='text-align: left'>- Tesouraria não
          validada.</div>
          <div style='text-align: left'>- Não houve restrição na tesouraria.</div>`,

          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }

      setLoaderSearch(false);
      await calcTotalizadores(search);
    } else {
      const campos = [
        { name: 'Data da Movimentação', value: dta_mov },
        { name: 'Caixa', value: caixa.value },
        { name: 'Operador', value: operador.value },
      ];

      let messageToastInput = 'Para pesquisar, o campo ';
      const inputArr: any = [];

      campos.forEach(({ name, value }) => {
        if (!value) inputArr.push(name);
      });

      inputArr.forEach((name: string, index: number) => {
        if (inputArr.length - 1 === index) {
          messageToastInput += `${inputArr.length === 1 ? '' : ' e '}`;
          messageToastInput += `${name}`;
        } else {
          messageToastInput += `${index === 0 ? '' : ','}`;
          messageToastInput += ` ${name}`;
        }
      });

      return toast.warning(
        `${messageToastInput}${
          inputArr.length === 1
            ? ' deve ser selecionado.'
            : ' devem ser selecionados.'
        } `,
      );
    }
  }, [
    handlesearchRows,
    calcValDif,
    calculoDesFinalizadorCalc,
    calculoLiberar,
    getValues,
    handleDisableWhenSearch,
    setValue,
    statusConferencia,
    calcTotalizadores,
  ]);

  const handleCancel = useCallback(() => {
    const fields = getValues();
    const registeredFields = Object.keys(fields);
    registeredFields.forEach((element) => {
      if (element.includes('cod_tesouraria-')) {
        unregister(`${element}`);
      }
    });

    handlesearchRows([]);
    handledisableButtonClear(false);
    handledisableButtonCancel(true);
    setDisableButtonValidar(true);
    handleDisableWhenSearch(false);
    setDisabledButtonImprimir(true);

    setStatus('');
    setValDifTotal(0);
    setConfLiberadorTotal(0);
    setTotalizadores(totalizadoresDefault);

    setValue('totalizadores', totalizadoresDefault);
  }, [
    handledisableButtonCancel,
    handledisableButtonClear,
    handlesearchRows,
    handleDisableWhenSearch,
    getValues,
    unregister,
    setValue,
    totalizadoresDefault,
  ]);
  const handleGetTurnos = useCallback(async () => {
    const { dta_mov, lojas, caixa, operador } = getValues();

    if (!dta_mov || !lojas || !caixa || !operador) {
      return handledisableTurno(true);
    }
    const turnos = await getTurno(dta_mov, caixa.value, operador.value, lojas);
    handleOptionsTurno(turnos);
    handledisableTurno(false);
  }, [getValues, handleOptionsTurno, handledisableTurno]);

  const handleGetPdv = useCallback(async () => {
    const { dta_mov } = getValues();

    const pdvs = await getPdvs(dta_mov, lojaConferencia);

    setValue('caixa', '');
    setValue('operador', '');
    handleOptionsOperador([]);

    if (pdvs.length === 0) {
      handleOptionsPdv([]);
      return toast.warning('Caixa não encontrado para a data da movimentação ');
    }

    const optionsCaixa = pdvs.map((value: any) => {
      return { value: value.num_pdv, label: String(value.num_pdv) };
    });
    handledisableCaixa(false);
    handleOptionsPdv(optionsCaixa);
  }, [
    getValues,
    handleOptionsOperador,
    handleOptionsPdv,
    handledisableCaixa,
    lojaConferencia,
    setValue,
  ]);

  const handleChangeLojaAndDate = useCallback(() => {
    const { dta_mov } = getValues();

    if (lojaConferencia > 0 && dta_mov) {
      setValue('lojas', lojaConferencia);
      handleGetPdv();
    } else {
      handledisableCaixa(true);
    }
  }, [getValues, handleGetPdv, handledisableCaixa, lojaConferencia, setValue]);

  const handleGetOperador = useCallback(async () => {
    const { dta_mov, lojas, caixa } = getValues();
    const operadores = await getOperador(dta_mov, lojas, caixa.value);

    if (operadores.length === 0) {
      return toast.warning('Operador não encontrado para o caixa selecionado');
    }
    handledisableOperador(false);
    handleOptionsOperador(operadores);
  }, [getValues, handleOptionsOperador, handledisableOperador]);

  const handleChangeCaixa = useCallback(() => {
    const caixa = getValues('caixa');
    setValue('operador', '');
    setValue('turno', '');
    if (caixa) {
      handleGetOperador();
    } else {
      handledisableOperador(true);
    }
  }, [getValues, handleGetOperador, handledisableOperador, setValue]);

  const resetInput = useCallback(async () => {
    handleLoja(loja.cod_loja);
    reset();
    setValue('dta_mov', '');
    setValue('caixa', { value: undefined, label: '' });
    setValue('operador', { value: undefined, label: '' });
    setValue('turno', { value: undefined, label: '' });
    setValue('totalizadores', totalizadoresDefault);
    handledisableCaixa(true);
    handledisableOperador(true);
    handledisableTurno(true);
  }, [
    reset,
    handleLoja,
    handledisableCaixa,
    handledisableOperador,
    handledisableTurno,
    setValue,
    loja.cod_loja,
    totalizadoresDefault,
  ]);

  return (
    <LiberacaoTesourariaContext.Provider
      value={{
        handleLoja,
        handleChangeLojaAndDate,
        handledisableCaixa,
        handleChangeCaixa,
        handleSearch,
        resetInput,
        handleCancel,
        handleGetTurnos,
        handleDelete,
        handleImprimir,
        handleValidar,
        calculoValDif,
        handleConfLiberador,
        valDifTotal,
        lojaConferencia,
        disableInputs,
        optionsPdv,
        disableCaixa,
        optionsOperador,
        disableOperador,
        optionsTurno,
        disableTurno,
        loaderSearch,
        disableButtonCancel,
        disableButtonClear,
        disableButtonSearch,
        disableButtonValidar,
        disabledButtonImprimir,
        searchRows,
        totalizadores,
        status,
        confLiberadorTotal,
        setSearchRows,
        setValue,
        register,
        control,
        getValues,
        errors,
      }}
    >
      {children}
    </LiberacaoTesourariaContext.Provider>
  );
}

export const useLiberacaoTesouraria = (): LiberacaoTesourariaContextData => {
  return useContext(LiberacaoTesourariaContext);
};
